import { Forward } from "components/common/Icons";
import { useRouter } from "next/router";
import { TYPO } from "styles/Typo";

function MyStockService({
  isLoginUser,
  accountConnectUser = false,
}: {
  isLoginUser: boolean;
  accountConnectUser?: boolean;
}) {
  const router = useRouter();
  const contetns = [
    {
      title: "KB증권 계좌 연결하기",
      clickHandler: () => {
        if (!isLoginUser) {
          router.push("/login");
        } else {
          router.push("/kb/welcome");
        }
      },
      icon: () => {
        return <ConnectIcon />;
      },
      visible: !accountConnectUser,
    },
    {
      title: "산업 상승세 강한 종목 찾기",
      clickHandler: () => {
        router.push("/mmtranking?loc=korea");
      },
      icon: () => {
        return <IndustryIcon />;
      },
      visible: true,
    },
    {
      title: "리스크 관리 잘하는 종목 찾기",
      clickHandler: () => {
        router.push("/sharperanking?loc=korea");
      },
      icon: () => {
        return <RiskIcon />;
      },
      visible: true,
    },
  ];

  const ListTemp = ({ content }: { content: any }) => {
    return (
      <div
        className="px-6 py-2 flex items-center gap-3 cursor-pointer"
        onClick={content.clickHandler}
        style={{ display: content?.visible ? "flex" : "none" }}
      >
        <div className="flex items-center justify-center">{content.icon()}</div>
        <span className={`${TYPO.TYPO5_1_MD} text-gray80 grow`}>
          {content.title}
        </span>
        <Forward fill={"gray30"} height={16} width={16} />
      </div>
    );
  };
  return (
    <div className="flex flex-col w-full gap-2">
      <div className="px-6 pt-6">
        <span className={`${TYPO.TYPO4_SB} text-gray80`}>서비스</span>
      </div>
      <div className="py-2 flex flex-col gap-2 w-full">
        {contetns.map((content: any, index: number) => {
          return <ListTemp content={content} key={index} />;
        })}
      </div>
    </div>
  );
}

const ConnectIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9688 10.6428C24.9688 10.4607 24.8697 10.293 24.7103 10.2051L15.2415 4.98092C15.0912 4.89797 14.9088 4.89797 14.7585 4.98092L5.28971 10.2051C5.13026 10.293 5.03125 10.4607 5.03125 10.6428V12.2539C5.03125 12.53 5.25511 12.7539 5.53125 12.7539H7.4375V21.6914H5.53125C5.25511 21.6914 5.03125 21.9153 5.03125 22.1914V24.6289C5.03125 24.905 5.25511 25.1289 5.53125 25.1289H24.4688C24.7449 25.1289 24.9688 24.905 24.9688 24.6289V22.1914C24.9688 21.9153 24.7449 21.6914 24.4688 21.6914H22.5625V12.7539H24.4688C24.7449 12.7539 24.9688 12.53 24.9688 12.2539V10.6428ZM19.125 12.7539H16.7188V21.6914H19.125V12.7539ZM13.2813 12.7539H10.875V21.6914H13.2813V12.7539ZM15 11.0352C15.9492 11.0352 16.7188 10.2656 16.7188 9.31641C16.7188 8.36717 15.9492 7.59766 15 7.59766C14.0508 7.59766 13.2813 8.36717 13.2813 9.31641C13.2813 10.2656 14.0508 11.0352 15 11.0352Z"
        fill="#4589FF"
      />
    </svg>
  );
};

const IndustryIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path d="M9.4375 7.3125H12.5625V13.5625H9.4375V7.3125Z" fill="#FA4D56" />
      <path
        d="M8.5 5.9375C8.5 5.59232 8.77982 5.3125 9.125 5.3125H12.875C13.2202 5.3125 13.5 5.59232 13.5 5.9375V7.1875C13.5 7.53268 13.2202 7.8125 12.875 7.8125H9.125C8.77982 7.8125 8.5 7.53268 8.5 7.1875V5.9375Z"
        fill="#262E36"
      />
      <path d="M18.5 7.3125H21.625V13.5625H18.5V7.3125Z" fill="#FA4D56" />
      <path
        d="M17.5625 5.9375C17.5625 5.59232 17.8423 5.3125 18.1875 5.3125H21.9375C22.2827 5.3125 22.5625 5.59232 22.5625 5.9375V7.1875C22.5625 7.53268 22.2827 7.8125 21.9375 7.8125H18.1875C17.8423 7.8125 17.5625 7.53268 17.5625 7.1875V5.9375Z"
        fill="#262E36"
      />
      <path
        d="M6 23.5V9.72108C6 9.16172 6.59021 8.79921 7.08912 9.05212L15 13.0625V9.85665C15 9.26685 15.6494 8.90784 16.1489 9.22152L23.0637 13.5641C23.6463 13.9301 24 14.5698 24 15.2578V23.5C24 24.1904 23.4404 24.75 22.75 24.75H7.25C6.55964 24.75 6 24.1904 6 23.5Z"
        fill="#C3CCD5"
      />
      <path
        d="M10 16.625C10 16.2798 10.2798 16 10.625 16H11.375C11.7202 16 12 16.2798 12 16.625V17.375C12 17.7202 11.7202 18 11.375 18H10.625C10.2798 18 10 17.7202 10 17.375V16.625Z"
        fill="#647A90"
      />
      <path
        d="M14 16.625C14 16.2798 14.2798 16 14.625 16H15.375C15.7202 16 16 16.2798 16 16.625V17.375C16 17.7202 15.7202 18 15.375 18H14.625C14.2798 18 14 17.7202 14 17.375V16.625Z"
        fill="#647A90"
      />
      <path
        d="M18 16.625C18 16.2798 18.2798 16 18.625 16H19.375C19.7202 16 20 16.2798 20 16.625V17.375C20 17.7202 19.7202 18 19.375 18H18.625C18.2798 18 18 17.7202 18 17.375V16.625Z"
        fill="#647A90"
      />
      <path
        d="M13.5 21.375C13.5 21.0298 13.7798 20.75 14.125 20.75H15.875C16.2202 20.75 16.5 21.0298 16.5 21.375V24.75H13.5V21.375Z"
        fill="#4F6272"
      />
    </svg>
  );
};
const RiskIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <rect width="30" height="30" fill="white"></rect>
      <path
        d="M6.50006 14.4118H14.8024V25C10.0582 23.4018 7.29076 20.9196 6.50006 19.1216V14.4118Z"
        fill="#7A8AA0"
      ></path>
      <path
        d="M23.5 14.4118H15.1977V25C19.9419 23.4018 22.7093 20.9196 23.5 19.1216V14.4118Z"
        fill="#293547"
      ></path>
      <path
        d="M14.8024 14.0196H6.50006L6.5 6.85811L14.8024 5V14.0196Z"
        fill="#CAD0D9"
      ></path>
      <path
        d="M15.1977 14.0196H23.5L23.4999 6.85811L15.1977 5V14.0196Z"
        fill="#7A8AA0"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5 14.4118H14.8024V14.0196H23.5V14.4118Z"
        fill="#636D7D"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1977 5V14.4118H14.8024V5H15.1977Z"
        fill="#9EAAB7"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1977 14.4118V25H14.8024V14.4118H15.1977Z"
        fill="#636D7B"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8024 14.4118H6.50006V14.0196H14.8024V14.4118Z"
        fill="#95A1B2"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.1977 14.4118H14.8024V14.0196H15.1977V14.4118Z"
        fill="#8590A0"
      ></path>
    </svg>
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   width="40"
    //   height="40"
    //   viewBox="0 0 40 40"
    //   fill="none"
    // >
    //   <rect width="40" height="40" rx="20" fill="#F6F7F9" />
    //   <path
    //     d="M11.0001 20.3529H19.7907V32C14.7675 30.242 11.8373 27.5116 11.0001 25.5338V20.3529Z"
    //     fill="#7A8AA0"
    //   />
    //   <path
    //     d="M29 20.3529H20.2093V32C25.2326 30.242 28.1628 27.5116 29 25.5338V20.3529Z"
    //     fill="#293547"
    //   />
    //   <path
    //     d="M19.7907 19.9216H11.0001L11 12.0439L19.7907 10V19.9216Z"
    //     fill="#CAD0D9"
    //   />
    //   <path
    //     d="M20.2093 19.9216H29L28.9999 12.0439L20.2093 10V19.9216Z"
    //     fill="#7A8AA0"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M29 20.3529H19.7907V19.9216H29V20.3529Z"
    //     fill="#636D7D"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M20.2093 10V20.3529H19.7907V10H20.2093Z"
    //     fill="#9EAAB7"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M20.2093 20.3529V32H19.7907V20.3529H20.2093Z"
    //     fill="#636D7B"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M19.7907 20.3529H11.0001V19.9216H19.7907V20.3529Z"
    //     fill="#95A1B2"
    //   />
    //   <path
    //     fillRule="evenodd"
    //     clipRule="evenodd"
    //     d="M20.2093 20.3529H19.7907V19.9216H20.2093V20.3529Z"
    //     fill="#8590A0"
    //   />
    // </svg>
  );
};

export default MyStockService;
