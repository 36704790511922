import SelectAccountModal from "components/modals/KB/SelectAccountModal";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { TYPO } from "styles/Typo";
import { useKBGlobalState } from "contexts/KBContext";

function AccountInfo({
  balanceData,
  waitingOrderList,
  noPasswordHandler,
  waitingMutate,
}: {
  balanceData: any;
  waitingOrderList: any;
  noPasswordHandler: any;
  waitingMutate: any;
}) {
  const router = useRouter();
  const [accountSelectModal, setAccountSelectModal] = useState(false);
  const { accounts, currentAccount, isLoading, isAccountLoading } =
    useKBGlobalState();

  useEffect(() => {
    waitingMutate();
  }, []);

  return (
    <div>
      <div className="flex pt-6 px-6 justify-between items-center">
        <span className={`${TYPO.TYPO5_B} text-gray80`}>내 계좌</span>
        <div className="flex justify-end items-center">
          <div
            className={`${
              accounts?.length > 1 ? "cursor-pointer" : ""
            } flex items-center gap-1`}
            onClick={() => {
              if (accounts?.length === 1) return;
              setAccountSelectModal(true);
            }}
          >
            <span className={`${TYPO.TYPO7_MD} text-gray50`}>
              {currentAccount?.stockName}
            </span>
            {accounts?.length > 1 && <ArrowDown />}
          </div>
          {accountSelectModal && (
            <SelectAccountModal
              isModalOpen={accountSelectModal}
              setModalOpen={setAccountSelectModal}
            />
          )}
        </div>
      </div>
      <div className="py-2 flex flex-col gap-2">
        <div
          className="px-6 py-2 flex gap-3 items-center cursor-pointer"
          onClick={async () => {
            const noPassword = await noPasswordHandler();
            if (!noPassword) return;
            router.push("/kb/accountdetail");
          }}
        >
          <WonIcon />
          <div className={`flex flex-col`}>
            <span className={`${TYPO.TYPO8_RG} text-gray60`}>
              주문 가능 금액
            </span>
            <span className={`${TYPO.TYPO5_1_SB} text-gray90`}>
              {balanceData?.balanceTotal?.toLocaleString("en-us")}원
            </span>
          </div>
        </div>
        {waitingOrderList?.length > 0 && (
          <div
            className="px-6 py-2 flex gap-3 items-center cursor-pointer bg-blue5"
            onClick={async () => {
              const noPassword = await noPasswordHandler();
              if (!noPassword) return;
              router.push("/kb/order/list");
            }}
          >
            <WaitIcon />
            <div className={`flex flex-col`}>
              <span className={`${TYPO.TYPO8_RG} text-gray60`}>
                대기 중인 주문
              </span>
              <span className={`${TYPO.TYPO5_1_SB} text-gray90`}>
                {waitingOrderList?.length}건
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const WonIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <circle cx="18.0008" cy="17.9969" r="13.2" fill="#9FADBC" />
      <path
        d="M12.7656 13.8047L15.3839 22.9691L18.0022 13.8047L20.6204 22.9691L23.2387 13.8047"
        stroke="white"
        strokeWidth="2.016"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8008 17.7422L13.4191 17.7422"
        stroke="white"
        strokeWidth="2.016"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.582 17.7422L25.2003 17.7422"
        stroke="white"
        strokeWidth="2.016"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
const WaitIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <circle cx="18.0008" cy="17.9969" r="13.2" fill="#9FADBC" />
      <circle cx="12" cy="18" r="2" fill="white" />
      <circle cx="18" cy="18" r="2" fill="white" />
      <circle cx="24" cy="18" r="2" fill="white" />
    </svg>
  );
};

const ArrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
    >
      <path
        d="M6.02911 7.6375L9.70411 3.9625C9.82911 3.8375 9.97494 3.77708 10.1416 3.78125C10.3083 3.78542 10.4541 3.85 10.5791 3.975C10.7041 4.1 10.7666 4.24583 10.7666 4.4125C10.7666 4.57917 10.7041 4.725 10.5791 4.85L6.74161 8.7C6.64161 8.8 6.52911 8.875 6.40411 8.925C6.27911 8.975 6.15411 9 6.02911 9C5.90411 9 5.77911 8.975 5.65411 8.925C5.52911 8.875 5.41661 8.8 5.31661 8.7L1.46661 4.85C1.34161 4.725 1.28119 4.57708 1.28536 4.40625C1.28952 4.23542 1.35411 4.0875 1.47911 3.9625C1.60411 3.8375 1.74994 3.775 1.91661 3.775C2.08327 3.775 2.22911 3.8375 2.35411 3.9625L6.02911 7.6375Z"
        fill="#8194A7"
      />
    </svg>
  );
};
export default AccountInfo;
