import Tab from "components/common/Tab/tab";
import Tabs from "components/common/Tab/tabs";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { TYPO } from "styles/Typo";

function MainNavgation({ isMobile }: { isMobile: boolean }) {
  const router = useRouter();
  const { type } = router.query;
  return (
    <div className="w-full">
      <Tabs type="line">
        <Tab
          title={router.locale === "ko" ? "발견" : "EXPLORE"}
          onClick={() => {
            window.dataLayer.push({
              event: "cMainTabExplore",
            });
            router.push("?type=explore", undefined, { shallow: true });
          }}
          isActive={!type || type === "" || type === "explore"}
          type={"line"}
          tabClassNameAdd={`!py-5 ${TYPO.TYPO5_SB} ${
            !type || type === "" || type === "explore"
              ? "!border-b-[2.5px]"
              : ""
          }`}
        />
        <Tab
          title={"MY"}
          onClick={() => {
            window.dataLayer.push({
              event: "cMainTabMy",
            });
            router.push("?type=my", undefined, { shallow: true });
          }}
          isActive={type === "my"}
          type={"line"}
          tabClassNameAdd={`!py-5 ${TYPO.TYPO5_SB} ${
            type === "my" ? "!border-b-[2.5px]" : ""
          }`}
        />
        {isMobile && router.locale === "ko" && (
          <Tab
            title={"내 주식"}
            onClick={() => {
              window.dataLayer.push({
                event: "cMainTabMy",
              });
              router.push("?type=stock", undefined, { shallow: true });
            }}
            isActive={type === "stock"}
            type={"line"}
            tabClassNameAdd={`!py-5 ${TYPO.TYPO5_SB} ${
              type === "stock" ? "!border-b-[2.5px]" : ""
            }`}
          />
        )}
      </Tabs>
    </div>
  );
}

export default MainNavgation;
