import { AddPasswordOverlayer, useKbPassword } from "utils/kb/password";
import AccountInfo from "./Account";
import MyStockLists from "./Stocks";
import { useKBGlobalState } from "contexts/KBContext";
import { useEffect, useState } from "react";
import {
  useKbAccountBalance,
  useKbTotalEvalAmout,
  useKbTotalValuations,
  useKbWaitingOrderList,
} from "utils/clientAPIs/accountsCheck";
import KbConnectAd from "./KbConnectAd";
import HighTotalScoreStocks from "./HighTotalScoreStocks";
import MyStockService from "./Service";
import DividerLine from "components/common/divider/line";
import StrateRanking from "./StrateRanking";
import { useSession } from "next-auth/react";
import KBServerError from "../../KB/ServerError";
import TermsAgreePage from "../../KB/TermsAgree";
import { MyStockSkeleton } from "components/skeleton/KB";
import TermsAgreeExpired from "../../KB/TermsAgreeExpired";

function MyStockIndex({
  isMobile,
  isLoginUser,
}: {
  isMobile: boolean;
  isLoginUser: boolean;
}) {
  const { data: session }: any = useSession();
  // const [valuationError, setValuationError] = useState<any>(null);\
  const {
    isAccountLoading,
    currentAccount,
    isLoading,
    kbAuth,
    setKbAuth,
    setReloadPassword,
    setEncryptedPasswords,
  } = useKBGlobalState();

  const [isPasswordGetLoading, setIsPasswordGetLoading] = useState(true);
  const [
    encryptedPassword,
    PasswordScreen,
    inPasswordMode,
    loading,
    getPasswordFetch,
  ] = useKbPassword(isMobile, true, isLoading);

  const [noPassword, setNoPassword] = useState(false);
  const [balanceData, isBalanceLoading, balacneApiMutate, balanceError] =
    useKbAccountBalance(currentAccount?.accountNo || null, encryptedPassword);

  const [
    waitingOrderList,
    isWaitingOrdersLoading,
    waitingMutate,
    waitingError,
    isTermsAgreedError,
    isPasswordCacheError,
  ] = useKbWaitingOrderList(currentAccount?.accountNo, encryptedPassword);

  // 보유 종목 정보 (수량, 평가금, 평가손익 ()) -> 환율 전환
  const [valuationData, isValuationLoading, valuationMuuate, valuationError] =
    useKbTotalValuations(currentAccount?.accountNo || null);

  const [totalValuation, isTotalValuationLoading, mutate, totalValuationError] =
    useKbTotalEvalAmout(currentAccount?.accountNo || null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setEncryptedPasswords(null);
    setReloadPassword((prev: any) => !prev);
    setTimeout(() => {
      setIsPasswordGetLoading(false);
    }, 1000);
  }, []);
  // useEffect(() => {
  //   if (encryptedPassword) {
  //     waitingMutate();
  //     balacneApiMutate();
  //   }
  // }, [loading]);

  const noPasswordHandler = async () => {
    const newPassword = await getPasswordFetch(
      currentAccount?.accountNo || null,
      session?.user?.email || null
    );
    if (!newPassword) {
      setNoPassword(true);
      return false;
    } else return true;
  };

  if (!isLoginUser) {
    return (
      <div className="flex flex-col bg-white w-full items-center justify-center gap-4">
        <KbConnectAd />
        <HighTotalScoreStocks />
        <MyStockService isLoginUser={isLoginUser} />
        <DividerLine size="lg" />
        <StrateRanking />
      </div>
    );
  }

  if (isAccountLoading)
    return (
      <div className="bg-white w-full">
        <MyStockSkeleton />
      </div>
    );

  if (!currentAccount)
    return (
      <div className="bg-white w-full flex items-center justify-center flex-col gap-4 min-h-[85vh]">
        <KbConnectAd />
        <HighTotalScoreStocks />
        <MyStockService isLoginUser={isLoginUser} />
        <DividerLine size="lg" />
        <StrateRanking />
      </div>
    );
  if (!kbAuth || kbAuth?.status === "loading" || (isLoading && currentAccount))
    return (
      <div className="bg-white w-full">
        <MyStockSkeleton />
      </div>
    );

  if (kbAuth && !kbAuth.status) {
    const isTermsError = kbAuth?.isTermsError;
    if (isTermsError) {
      return (
        <TermsAgreeExpired
          setModalOpen={() => {
            setKbAuth(null);
          }}
        />
      );
    }
    return <KBServerError errorId={"KA01"} errorContents={kbAuth?.error} />;
  }

  if (isBalanceLoading || isWaitingOrdersLoading || isPasswordGetLoading)
    return (
      <div className="bg-white w-full">
        <MyStockSkeleton />
      </div>
    );

  if (inPasswordMode || waitingOrderList?.passwordCacheError)
    return <PasswordScreen />;
  // if () {
  //   return <div className="bg-white w-full"></div>;
  // }
  if (balanceError) {
    return (
      <KBServerError
        errorContents={balanceError?.response?.data?.error}
        errorId={"KM01"}
      />
    );
  }
  if (waitingError)
    return (
      <KBServerError
        errorId={"KM07"}
        errorContents={waitingError?.response?.data?.error}
      />
    );
  if (valuationError || totalValuationError) {
    const error = valuationError || totalValuationError;
    const isHpinError = error?.response?.data?.error?.bizUnit === "oapi0202";
    const isServerError = !error?.response?.data?.error?.bizUnit;
    const isExchangeError =
      typeof error?.response?.data?.error === "string" &&
      error?.response?.data?.error?.includes("Exchange");
    return (
      <KBServerError
        errorContents={error?.response?.data?.error}
        errorId={
          isHpinError
            ? "KM02"
            : isServerError
            ? isExchangeError
              ? "KM04"
              : "KM05"
            : "KM03"
        }
      />
    );
  }

  return (
    <div className="flex flex-col bg-white">
      {!inPasswordMode && (
        <>
          {noPassword && <AddPasswordOverlayer setNoPassword={setNoPassword} />}
          <AccountInfo
            balanceData={balanceData}
            waitingOrderList={waitingOrderList}
            noPasswordHandler={noPasswordHandler}
            waitingMutate={waitingMutate}
          />
          <MyStockLists
            // setValuationError={setValuationError}
            isLoginUser={isLoginUser}
            noPasswordHandler={noPasswordHandler}
            isValuationLoading={isValuationLoading}
            isTotalValuationLoading={isTotalValuationLoading}
            valuationError={valuationError}
            totalValuationError={totalValuationError}
            valuationData={valuationData}
            totalValuation={totalValuation}
          />
        </>
      )}
    </div>
  );
}

export default MyStockIndex;
