import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import useMainStockList from "utils/clientAPIs/MainStockList";
import Strategy from "./Strategy";
import { StockListSkeleton } from "components/skeleton/main";
import StockList from "./StockList";
import { useTranslation } from "next-i18next";
import CustomToast, {
  KbPasswordReAuthToast,
} from "components/atoms/CustomToast";
import { useKbPassword } from "utils/kb/password";
import { passwordExpiredToastState } from "states/KbState";
import { useRecoilState } from "recoil";
import { useKBGlobalState } from "contexts/KBContext";

function StockLIstIndex({ isMobile }: { isMobile: boolean }) {
  const router = useRouter();
  const [passwordExpiredToast, setPasswordExpiredToast] = useRecoilState(
    passwordExpiredToastState
  );
  const { mainScreenPasswordSuccess, setMainScreenPasswordSuccess } =
    useKBGlobalState();
  const { t } = useTranslation("main");
  const [koreanData, koreanIndex, isKoreanLoading] = useMainStockList({
    loc: "korea",
  });
  const [usData, usIndex, isUsLoading] = useMainStockList({ loc: "us" });
  const [cryptoData, _, isCryptoLoading] = useMainStockList({ loc: undefined });
  const [reAuthPassword, setReAuthPassword] = useState(false);
  const [
    encryptedPassword,
    PasswordScreen,
    inPasswordMode,
    load,
    getPasswordFetch,
  ] = useKbPassword(isMobile, false);
  const loading = isKoreanLoading || isUsLoading || isCryptoLoading;

  if (reAuthPassword && inPasswordMode) {
    return (
      <PasswordScreen
        backHandler={() => {
          setReAuthPassword(false);
        }}
      />
    );
  }

  return (
    <div>
      <Strategy isMobile={isMobile} />
      {loading && (
        <div>
          <StockListSkeleton />
          <StockListSkeleton />
        </div>
      )}
      {!loading && (
        <StockList
          cat={"korean"}
          name={t("korean")}
          stocklist={[...koreanData]}
          indexList={[...koreanIndex]}
        />
      )}
      {!loading && (
        <StockList
          cat={"us"}
          name={t("us")}
          stocklist={[...usData]}
          indexList={[...usIndex]}
        />
      )}
      {!loading && (
        <StockList
          cat={"crypto"}
          name={t("crypto")}
          stocklist={[...cryptoData]}
        />
      )}
      <KbPasswordReAuthToast
        isVisible={passwordExpiredToast}
        onClose={() => setPasswordExpiredToast(false)}
        width={300}
        buttonHanlder={() => {
          setReAuthPassword(true);
          setPasswordExpiredToast(false);
        }}
      />
      <CustomToast
        isVisible={mainScreenPasswordSuccess}
        onClose={() => setMainScreenPasswordSuccess(false)}
        message={"KB증권 계좌로 인증되었어요"}
        width={270}
        duration={1500}
        successIconFill={"#34BB7A"}
      />
    </div>
  );
}

export default StockLIstIndex;
