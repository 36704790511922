import { KOREA } from "utils/constant/ITME_INFO";

export const selectColor = (value: number) => {
  console.log('value.toFixed(2)', value.toFixed(2));
  if (Number(value) > 0 && value.toFixed(2) !== '0.00') return "text-red40";
  if (Number(value) === 0 || value.toFixed(2) === '0.00') return "text-gray50";
  return "text-blue40";
}


// 전체 평가 손익

export const convertTotalVarPl = (data?: any) => {
  if (!data) return null;
  const { LOC } = data;
  const totalValPl = Number(data?.totalValPl?.toFixed(0))
  const isPlus = totalValPl > 0;
  const plus = isPlus ? "+" : totalValPl === -0 ? "" : "-";

  return plus + Math.abs(totalValPl)?.toLocaleString('ko-KR', {
    maximumFractionDigits: 0,
    minimumFractionDigits: 0
  }) + "원";
}


// 전체 손익률
export const convertRate = (data?: any) => {
  if (!data) return null;
  const { LOC } = data;
  const totalPlRate = Number(data?.totalPlRate?.toFixed(1)) || 0
  const isPlus = totalPlRate > 0;
  const plus = isPlus ? "+" : totalPlRate === -0 ? "" : "-";

  return plus + Math.abs(totalPlRate)?.toLocaleString('ko-KR', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1
  }) + "%";

}

export const convertKrRate = (data?: any) => {
  if (!data) return null;
  const { LOC } = data;
  const totalPlRateKr = Number(data?.totalPlRateKr?.toFixed(1)) || 0
  const isPlus = totalPlRateKr > 0;
  const plus = isPlus ? "+" : totalPlRateKr === -0 ? "" : "-";

  return plus + Math.abs(totalPlRateKr)?.toLocaleString('ko-KR', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1
  }) + "%";
}

export const convertUsRate = (data?: any) => {
  if (!data) return null;
  const { LOC } = data;
  const totalPlRateUs = Number(data?.totalPlRateUs?.toFixed(1)) || 0
  const isPlus = totalPlRateUs > 0;
  const plus = isPlus ? "+" : totalPlRateUs === -0 ? "" : "-";

  return plus + Math.abs(totalPlRateUs)?.toLocaleString('ko-KR', {
    maximumFractionDigits: 1,
    minimumFractionDigits: 1
  }) + "%";
}


//개별 종목 평가금액 (원, 달러/ 미국종목만)
export const convertvalAmt = (wonActive: boolean, data?: any,) => {
  if (!data) return null;
  const { LOC } = data;
  if (!wonActive) {
    if (LOC === KOREA) {
      return data?.valAmt?.toLocaleString('ko-KR', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      }) + "원";

    }
    return '$' + data?.valAmtUs?.toLocaleString('en-us', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
  } else {
    return data?.valAmt?.toLocaleString('ko-KR', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }) + "원";
  }
};

//평가손익 (원, 달러 / 미국종목만)
export const convertvalPl = (wonActive: boolean, data?: any,) => {
  if (!data) return null;
  const { LOC } = data;
  const krValPl = Number(data?.valPl.toFixed(0))
  const usValPl = Number(data?.valPlUs.toFixed(2))
  const isPlus = LOC === KOREA ? data?.valPl > 0 : data?.valPlUs > 0;
  const plus = isPlus ? "+" : LOC === KOREA ? krValPl === -0 ? "" : "-" : usValPl === -0 ? "" : "-";

  if (!wonActive) {
    if (LOC === KOREA) {
      return plus + Math.abs(krValPl)?.toLocaleString('ko-KR', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      }) + "원";

    }
    return plus + Math.abs(usValPl)?.toLocaleString('en-us', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
  } else {
    return plus + Math.abs(krValPl)?.toLocaleString('ko-KR', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }) + "원";
  }
};

// 현재가 (원, 달러 / 미국종목만)
export const convertCurrentPrice = (wonActive: boolean, data?: any,) => {
  if (!data) return null;
  const { LOC } = data;

  if (!wonActive) {
    if (LOC === KOREA) {
      return data?.currentPriceKr?.toLocaleString('ko-KR', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      }) + "원";

    }
    return '$' + data?.currentPriceUs?.toLocaleString('en-us', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
  } else {
    return data?.currentPriceKr?.toLocaleString('ko-KR', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }) + "원";
  }
};

// 현재가 손익
export const convertCurrentPriceChange = (wonActive: boolean, data?: any,) => {
  if (!data) return null;
  const { LOC } = data;

  const krChange = Number(data?.currentPriceChangeKr.toFixed(0))
  const usChange = Number(data?.currnetPriceChangeUs.toFixed(2))
  const isPlus = LOC === KOREA ? krChange > 0 : usChange > 0;
  const plus = isPlus ? "+" : LOC === KOREA ? krChange === -0 ? "" : "-" : usChange === -0 ? "" : "-";


  if (!wonActive) {
    if (LOC === KOREA) {
      return plus + Math.abs(krChange)?.toLocaleString('ko-KR', {
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      }) + "원";

    }
    return plus + Math.abs(usChange)?.toLocaleString('en-us', {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2
    });
  } else {
    // conso
    return plus + Math.abs(krChange)?.toLocaleString('ko-KR', {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    }) + "원";
  }
};

export const chgFormat = (value: number) => {
  const r = value.toFixed(1);
  if (r === '-0.0') return '0.0';
  return r;
}