import Img from "components/common/List/img";
import Text from "components/common/List/Text";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { TYPO } from "styles/Typo";
import { useHighTotalScoreStockList } from "utils/clientAPIs/detailDiagnosis";
import { KOREA } from "utils/constant/ITME_INFO";
import InterestStar from "../../components/InterestStar";
import DividerButton from "components/common/divider/button";
import { useHighStrategyAssets } from "utils/clientAPIs/stragey";
import { HighTotalScoreSkeleton } from "components/skeleton/KB";

function StrateRanking({}: {}) {
  const router = useRouter();
  const [list, BASE_DT, loading, error] = useHighStrategyAssets();
  const [page, setPage] = useState(0);
  const [sliceData, setSliceData] = useState<any>([]);
  useEffect(() => {
    if (list) {
      setSliceData(list.slice(0, 3));
    }
  }, [list]);
  const handleNext = () => {
    if (list) {
      if (page < 2) {
        setPage(page + 1);
        setSliceData(list.slice((page + 1) * 3, (page + 2) * 3));
      } else {
        setPage(0);
        setSliceData(list.slice(0, 3));
      }
    }
  };
  const ListTemp = ({ asset, index }: { asset: any; index: number }) => {
    return (
      <li
        className={` w-full flex items-center  px-6 py-2  gap-3 bg-white `}
        onClick={() => router.push(`/detail/${asset.ITEM_CD_DL}`)}
      >
        <div className="justify-center items-center inline-flex">
          <span
            className={` text-center text-blue40  text-[15px] font-semibold leading-none whitespace-nowrap`}
          >
            {index + 1}
          </span>
        </div>
        <Img
          src={
            `https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/${asset.ITEM_CD_DL}.png` ||
            "https://riskweather-public.s3.ap-northeast-2.amazonaws.com/logos/errorLogo.png"
          }
          HR_ITEM_NM={""}
        />

        <div
          className={`justify-start grow  overflow-hidden whitespace-pre-wrap flex-col  items-start inline-flex `}
        >
          <Text
            item={
              router.locale === "ko"
                ? asset.ITEM_KR_NM && asset.ITEM_KR_NM
                : asset.ITEM_ENG_NM && asset.ITEM_ENG_NM
            }
            price={"이번달"}
            percent={asset.ADJ_CLOSE_CHG.toLocaleString("en-us", {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}
          />
        </div>

        <div className="flex items-center shrink-0 justify-end">
          <div>{<InterestStar asset={asset} size={30} />}</div>
        </div>
      </li>
    );
  };

  if (loading || error || sliceData?.length === 0)
    return (
      <div>
        <HighTotalScoreSkeleton />
      </div>
    );

  const date = BASE_DT
    ? `${BASE_DT?.split("-")?.[0].slice(-2)}년 ${Number(
        BASE_DT?.split("-")?.[1]
      )}월`
    : "";
  return (
    <div className="flex flex-col w-full gap-2">
      <div className="px-6 pt-6 flex flex-col gap-1">
        <span className={`${TYPO.TYPO8_RG} text-gray60`}>{date}</span>
        <span className={`${TYPO.TYPO4_SB} text-gray80`}>
          리스크웨더 AI가 고른 이달의 종목
        </span>
      </div>
      <div className="py-2 flex flex-col gap-2">
        {sliceData?.map((asset: any, index: number) => {
          return (
            <ListTemp asset={asset} key={index} index={index + 3 * page} />
          );
        })}
      </div>
      <DividerButton text={`더 보기 ${page + 1}/3`} onClick={handleNext} />
    </div>
  );
}

export default StrateRanking;
